import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import PageBannerImgBackground from '../components/PageBannerImgBackground/pageBannerImgBackground'
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';


function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

 const Contact = () => {

//     const [name, setName] = useState('')
//     const [email, setEmail] = useState('')
//     const [message, setMessage] = useState('')
//     const [bot, setBot] = useState('')




//     const encode = (data) => {
//         return Object.keys(data)
//             .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
//             .join("&");
//       }


//    const handleSubmit = (e) => {

//     e.preventDefault();
//     const form = e.target

//         fetch("/", {
//           method: "POST",
//           headers: { "Content-Type": "application/x-www-form-urlencoded" },
//           body: encode({ "form-name": "contact",  name, email, message  } )
//         })
//         .then(() => navigate(form.getAttribute('action')))
//         .catch(error => alert(error));
//       };


const [state, setState] = React.useState({})

const handleChange = (e) => {
  setState({ ...state, [e.target.name]: e.target.value })
}

const handleSubmit = (e) => {
  e.preventDefault()
  const form = e.target
  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': form.getAttribute('name'),
      ...state,
    }),
  })
    .then(() => navigate(form.getAttribute('action')))
    .catch((error) => alert(error))
}


    return (
        <Layout
        title='Contact'
        description='Interaction. Engineering. Design.'
        >
            <PageBannerImgBackground 
            imgPath='/img/Contact-Optimized.jpg'
            title='Contact'
            description=' '
            />
            <div className='contact-wrapper'>
            <div className='left-column'>
                    <h2>Contact</h2>

                    <div className='content-box'>
                        <div className='title'> TIL North - DC Metro</div> 
                        Advanced AI and Data Prototyping Lab<br/>
                        8300 Greensboro Drive<br/>
                        Suite 1040<br/>
                        McLean, VA 22102
                        <div><PhoneIcon /> <a href="#"> +1 (212) 390-8111</a></div>
                    </div>


                    <div className='content-box'>
                        <div className='title'> TIL South - Tampa</div> 
                        Headquarters and DoD Software Factory<br/>
                        1320 E 9th Ave <br/>
                        Suite 100 <br/>
                        Tampa, FL 33605 
                        <div><PhoneIcon /> <a href="#"> +1 (813) 547-3558</a></div>
                    </div>

                    <div className='content-box'>
                        <div className='title'> TIL West - Nashville</div> 
                        Digital Engineering and Data Innovation Lab<br/>
                        2222 Rosa L Parks Blvd <br/>
                        Nashville, TN 37228 
                                            </div>





                    <div className='content-box'>
                        <div className='title'> Email</div> 
                        <div><EmailIcon /> <a href="#"> contact@theinclab.com</a></div>
                    </div>

            </div>


            {/* <form className='right-column' 
            onSubmit={handleSubmit}  action="/contact-thankyou" name="contact"  method="post" data-netlify="true" data-netlify-honeypot="bot-field"> */}
            <form 
            className='right-column'
             name="contact"
             method="post"
             action="/contact-status"
             data-netlify="true"
             data-netlify-honeypot="bot-field"
             onSubmit={handleSubmit}
            >


<input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don�t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>


                <h2>Let's Start a Conversation</h2>
                <div className='form-item'>
                <TextField  label="Name" type="text" name='name'  variant="outlined" onChange={handleChange} fullWidth/>

                </div>

                <div className='form-item'>
                <TextField  label="Email"  type='email' name='email' variant="outlined" onChange={handleChange} fullWidth />
                </div>

                <div className='form-item'>
                <TextField  label="Message" type="text" name='message' variant="outlined"  onChange={handleChange} fullWidth multiline maxRows={4} />
                </div>

                <div className='form-item'>
                    <div className='btn-black'> <button type='submit' >Submit </button>  </div>
                    {/* <div className='btn-black'> <button  >Submit </button>  </div> */}

                </div>



            </form>


            </div>
        </Layout>
    )
}


export default Contact
 